import {useEffect, useState, type FC} from 'react'
import {Dropdown, Table} from 'react-bootstrap'
import {BsThreeDotsVertical} from 'react-icons/bs'
import {MdOutlineAdminPanelSettings} from 'react-icons/md'
import {useAuth} from '../../../app/modules/auth'
import {useGetCompanyByIdQuery, useGetCompanyMutation} from '../../../services/companyServiceApi'
import {getUsersLimit} from '../../../utils/getUsersLimit'
import InviteColleague from '../homepage/InviteColleague'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './MembersArea.module.scss'

import axios from 'axios'
import moment from 'moment'
import {FaPowerOff} from 'react-icons/fa6'
import {MdDataUsage} from 'react-icons/md'
import {toast} from 'react-toastify'
import ExportIcon from '../icons/workflow/ExportIcon'
import AreyouSureModal from './components/AreYouSureModal'
import UserLimitModal from './components/UserLimitModal'

interface MembersAreaProps {}

const MembersArea: FC<MembersAreaProps> = () => {
  const {currentUser} = useAuth()
  const [usersLimit, setUsersLimit] = useState<number>(10)
  const [refetchCompany, setRefetchCompany] = useState(Date.now())
  const [company, setCompany] = useState<any>({
    id: 0,
    name: '',
    company_generated_amount: {},
    users: [],
  })

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [getCompany] = useGetCompanyMutation()

  const getCompanyData = async () => {
    try {
      const companyData = await getCompany({
        company_name: currentUser?.company_name,
        user_id: currentUser?.id,
      }).unwrap()
      setCompany(companyData)
    } catch (err) {
      return {err}
    }
  }
  const [page, setPage] = useState(1)
  const {
    data: companyData = {
      members: [
        {
          id: 0,
          avatar: '',
          first_name: 'Jonh',
          last_name: 'Doe',
          email: 'jonhdoe@gmail.com',
          subscription_status: '',
          user_generated_amount: {},
          companyUserLimit: {},
          created_at: '',
          role: 'ADMIN',
        },
      ],
      hasMoreMembers: false,
    },
  } = useGetCompanyByIdQuery({id: currentUser?.company_name, page})
  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const [showAreSureModal, setShowAreSureModal] = useState(false)
  const [areYouSureMessage, setAreYouSureMessage] = useState('')
  const [areYouSureId, setAreYouSureId] = useState(0)
  const [areYouSureStatus, setAreYouSureStatus] = useState('')
  const [areYouSureType, setAreYouSureType] = useState('')

  const [triggerStatusChange, setTriggerStatusChange] = useState<number | string>('')

  const handleStatusChange = (e: any) => {
    const newStatus = e.subscription_status === 'active' ? 'inactive' : 'active'
    const confirm = e.subscription_status === 'active' ? 'deactive' : 'active'

    setShowAreSureModal(true)
    setAreYouSureMessage(`Are you sure you want to ${confirm} ${e.first_name + ' ' + e.last_name}?`)
    setAreYouSureId(e.id)
    setAreYouSureStatus(newStatus)
    setAreYouSureType('DEACTIVE')
  }

  const handleMakeAdmin = (e: any) => {
    setShowAreSureModal(true)
    setAreYouSureMessage(`Are you sure you want to make ${e.first_name + ' ' + e.last_name} admin?`)
    setAreYouSureId(e.id)
    setAreYouSureType('MAKE_ADMIN')
  }

  const isAdmin = currentUser?.role === 'ADMIN'
  const [selectedUser, setSelectedUser] = useState<any>(null)

  const tableRows = companyData?.members?.map((item: any = {}) => {
    const {
      id,
      avatar,
      first_name,
      last_name,
      email,
      subscription_status,
      user_generated_amount,
      companyUserLimit,
      created_at,
      role,
    } = item
    const currentYear = new Date().getFullYear()
    const currentMonthName = new Date().toLocaleString('default', {month: 'long'}).toLowerCase()
    const usage = user_generated_amount[currentYear]?.[currentMonthName] || {}
    const {words = 0, images = 0, credits = 0} = usage
    const name = `${first_name} ${last_name}`
    const isUserAdmin = id === companyData?.admin_id
    const limit = companyUserLimit ? companyUserLimit : ({} as any)
    const {words: wordsLimit = 0, images: imagesLimit = 0, credits: creditsLimit = 0} = limit
    return (
      <tr
        key={id}
        style={{
          borderBottom: '1px solid #fff',
          height: '50px',
        }}
      >
        <td
          style={
            {
              // width: '270px',
            }
          }
        >
          <img
            style={{width: '36px', borderRadius: '50%', marginRight: '10px'}}
            src={avatar || '/new-design/image/avatar.png'}
            alt='member'
          />
          {name}
        </td>
        <td
          style={{
            width: '100px',
          }}
        >
          <span>{role}</span>
        </td>
        <td>{email}</td>
        <td>
          <div className={classes.members__tableUsage}>
            <div className={classes.members__tableUsage__progress}>
              <select className='form-select' aria-label='Default select example'>
                <option value='words'>
                  Words: {words}/{wordsLimit}
                </option>
                <option value='image'>
                  Images: {images}/{imagesLimit}
                </option>
                <option value='credit'>
                  Credits: {credits}/{creditsLimit}
                </option>
              </select>
            </div>
          </div>
        </td>
        <td>
          {subscription_status === 'active' ? (
            <span className={classes.status_active}>Active</span>
          ) : (
            <span className={classes.status_inactive}>Inactive</span>
          )}
        </td>

        <td>
          <p>{moment(created_at).format('MM/DD/YYYY')}</p>
        </td>
        <td style={{display: 'flex', justifyContent: 'center'}}>
          <Dropdown>
            <Dropdown.Toggle
              className={classes.dropdownToggle}
              variant='primary'
              id='dropdown-basic'
              disabled={isUserAdmin || !isAdmin}
            >
              <BsThreeDotsVertical />
            </Dropdown.Toggle>
            <Dropdown.Menu className={classes.dropdownMenu}>
              <>
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleStatusChange(item)}
                >
                  <FaPowerOff /> {subscription_status === 'active' ? 'Deactivate' : 'Activate'}
                </Dropdown.Item>
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => setSelectedUser(item)}
                >
                  <MdDataUsage /> Change Limit
                </Dropdown.Item>
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleMakeAdmin(item)}
                >
                  <MdOutlineAdminPanelSettings /> Make Admin
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  })

  useEffect(() => {
    if (currentUser && currentUser.company_name) {
      setUsersLimit(getUsersLimit(currentUser.subscription.name))
      getCompanyData()
    }
  }, [triggerStatusChange])

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const exportMembers = async () => {
    try {
      const API_URL = process.env.REACT_APP_API_URL

      const response = await axios.get(
        `${API_URL}/company/export_members/${currentUser?.company_name}`,
        {
          responseType: 'blob',
        }
      )

      if (response.status !== 200) {
        toast.error('Failed to download file')
      }

      const csvUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      const fileName = `Members-${Date.now()}.csv`
      link.href = csvUrl
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(csvUrl)
    } catch (err) {
      console.log(err)
      return {err}
    }
  }

  return (
    <div className={classes.members}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague />}
      />
      <div className={classes.members__topBlock}>
        <div className={classes.members__topBlock__headers}>
          <h2 className={classes.members__topBlock__headers__title}>Members</h2>
          <p>Manage or add new members {/* <span className='text-secondary'> </span> */}</p>
        </div>
        <div className={classes.members__topBlock__btnsBlock}>
          <button className={classes.export__btn} onClick={() => exportMembers()}>
            <ExportIcon /> Export CSV
          </button>

          <button
            onClick={handleVisibility}
            disabled={!isAdmin || usersLimit - company?.activeCompanyUsers > 0 ? false : true}
            title={
              usersLimit - company?.activeCompanyUsers > 0
                ? 'Upgrade your plan to invite team members'
                : ''
            }
          >
            Invite member
          </button>
        </div>
      </div>

      <UserLimitModal
        user={selectedUser}
        setSelectedUser={setSelectedUser}
        companyId={companyData?.id}
        currentUserId={currentUser?.id}
      />

      <AreyouSureModal
        areYouSureType={areYouSureType}
        showAreSureModal={showAreSureModal}
        setShowAreSureModal={setShowAreSureModal}
        areYouSureMessage={areYouSureMessage}
        areYouSureId={areYouSureId}
        areYouSureStatus={areYouSureStatus}
        companyData={companyData}
        setTriggerStatusChange={setTriggerStatusChange}
      />

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th>Member</th>
              <th>Role</th>
              <th>Email address</th>
              <th>Usage</th>
              <th>Status</th>
              <th>Created At</th>
              <th style={{display: 'flex', justifyContent: 'center'}}>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={7}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!companyData?.hasMoreMembers}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default MembersArea
