import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const financeTemplatesData = {
  workflows: [],
  templates: [
    {
      id: 1,
      header: 'Finance',
      title: 'Report Visualization ',
      description: 'Upload files to create powerful report visualizations in seconds.',
      url: 'report-visualization',
      axiosURL: 'generateVisualReport',
      axiosHeader: 'visualReport',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 132,
          title: 'Upload File (CSV, Excel)',
          type: 'upload',
          placeholder: '+ Upload File',
          axiosParamName: 'url',
        },

        {
          id: 13443,
          title: 'Describe what type of visualization you want',
          type: 'input',
          placeholder: 'Describe what type of visualization you want',
          axiosParamName: 'visualizationType',
          tooltipAnchor: 'Describe what type of visualization you want',
          tooltipText: "Describe what type of visualization you want'",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 64,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 2,
      header: 'Finance',
      title: 'Financial Report Analysis',
      description: 'Upload financial reports to receive summaries, analysis and recommendations.',
      url: 'financial-statement-summary',
      axiosURL: 'generateFinancialStatementSummary',
      axiosHeader: 'financialStatementSummary',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 132,
          title: 'Upload File (CSV, Excel)',
          type: 'upload',
          placeholder: '+ Upload File',
          axiosParamName: 'url',
          tooltipAnchor: 'description_mark',
          tooltipText: 'Upload balance sheet, income statement, cashflow statement and more',
        },
        {
          id: 133,
          title: 'What are the area of focus?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'areaOfFocus',
          tooltipAnchor: 'area_of_focus',
          tooltipText: 'Example: identify trends, highlight key figures, etc',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        // {
        //   id: 134,
        //   title: 'Length',
        //   options: ['long', 'short'],
        //   axiosParamName: 'length',
        // },
      ],
    },
    // {
    //   id: 2,
    //   header: 'Finance',
    //   title: 'Budget Manager',
    //   description: 'Upload files to get insights and recommendations on budgeting.',
    //   url: 'budget-manager',
    //   axiosURL: 'generateBudgetManager',
    //   axiosHeader: 'budgetManager',
    //   Img: OneLineIcon,
    //   formType: 'regular',
    //   relevanceRequest: false,
    //   easyRequest: true,
    //   imgGenerate: false,
    //   limits: {
    //     credits: 4,
    //   },
    //   requiredQuestions: [
    //     {
    //       id: 132,
    //       title: 'Upload File (.xlsx, .csv) ',
    //       type: 'upload',
    //       placeholder: '+ Upload File',
    //       axiosParamName: 'url',
    //       tooltipAnchor: 'description_mark',
    //       tooltipText: 'Upload a Excel or Spreadsheet file',
    //     },
    //   ],
    //   additionalQuestions: [],
    //   optionalSelect: [
    //     // {
    //     //   id: 134,
    //     //   title: 'Length',
    //     //   options: ['long', 'short'],
    //     //   axiosParamName: 'length',
    //     // },
    //   ],
    // },
    // {
    //   id: 3,
    //   header: 'Finance',
    //   title: 'Risk Assessments',
    //   description: 'Upload files to identify risks and receive suggestions on improvements.',
    //   url: 'risk-assessment',
    //   axiosURL: 'generateRiskAssessment',
    //   axiosHeader: 'riskAssessment',
    //   Img: OneLineIcon,
    //   formType: 'regular',
    //   relevanceRequest: false,
    //   easyRequest: true,
    //   imgGenerate: false,
    //   limits: {
    //     credits: 4,
    //   },
    //   requiredQuestions: [
    //     {
    //       id: 132,
    //       title: 'Upload File (.xlsx, .csv) ',
    //       type: 'upload',
    //       placeholder: '+ Upload File',
    //       axiosParamName: 'url',
    //       tooltipAnchor: 'description_mark',
    //       tooltipText: 'Upload a Excel or Spreadsheet file',
    //     },
    //   ],
    //   additionalQuestions: [],
    //   optionalSelect: [
    //     // {
    //     //   id: 134,
    //     //   title: 'Length',
    //     //   options: ['long', 'short'],
    //     //   axiosParamName: 'length',
    //     // },
    //   ],
    // },
  ],
}

export default financeTemplatesData
