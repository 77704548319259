import {useEffect, useState, type FC} from 'react'
import {Button, Dropdown, Form, InputGroup, Modal, Spinner} from 'react-bootstrap'
import {BsThreeDotsVertical} from 'react-icons/bs'
import {MdOutlineAdminPanelSettings} from 'react-icons/md'
import {
  useAllocateUserLimitMutation,
  useChangeUserStatusMutation,
  useGetCompanyByIdQuery,
  useGetCompanyMutation,
  useUpdateCompanyAdminMutation,
} from '../../../../services/companyServiceApi'
import classes from '../MembersArea.module.scss'

import {toast} from 'react-toastify'

const UserLimitModal = ({
  user,
  setSelectedUser,
  companyId,
  currentUserId,
}: {
  user: any
  setSelectedUser: any
  companyId: number
  currentUserId?: number
}) => {
  const [show, setShow] = useState(false)
  const {first_name, last_name, companyUserLimit} = user || {}
  const limit = companyUserLimit ? companyUserLimit : ({} as any)
  const {words = 0, images = 0, credits = 0} = limit

  const name = `${first_name} ${last_name}`
  const handleClose = () => {
    setShow(false)
    setSelectedUser(null)
  }
  const handleShow = () => setShow(true)
  const [submitLimit, {isLoading}] = useAllocateUserLimitMutation()
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const data = {
      user_id: user.id,
      company_id: companyId,
      words: formData.get('words'),
      images: formData.get('images'),
      credits: formData.get('credits'),
      admin_id: currentUserId,
    }
    submitLimit(data)
      .unwrap()
      .then(() => {
        toast.success(`User limit updated`)
        handleClose()
      })
      .catch((error) => {
        toast.error(error.data.message || `Error updating user limit for `)
      })
  }
  useEffect(() => {
    if (user) {
      handleShow()
    }
  }, [user])

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/\D+/g, '')
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{
            border: 'none',
          }}
        >
          <Modal.Title>Change user limit for "{name}"</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
              <label htmlFor='words'>Words Limit</label>
              <input
                onChange={handleInputChange}
                defaultValue={words}
                name='words'
                type='number'
                className='p-3 w-50'
                pattern='[0-9]+'
                style={{
                  borderRadius: '5px',
                  border: '1px solid #ced4da',
                  outline: 'none',
                }}
                required
              />
            </InputGroup>
            <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
              <label htmlFor='images'>Images Limit</label>
              <input
                onChange={handleInputChange}
                defaultValue={images}
                name='images'
                type='number'
                pattern='[0-9]+'
                className='p-3 w-50'
                style={{
                  borderRadius: '5px',
                  border: '1px solid #ced4da',
                  outline: 'none',
                }}
                required
              />
            </InputGroup>
            <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
              <label htmlFor='credits'>Credits Limit</label>
              <input
                onChange={handleInputChange}
                defaultValue={credits}
                name='credits'
                type='number'
                className='p-3 w-50'
                pattern='[0-9]+'
                style={{
                  borderRadius: '5px',
                  border: '1px solid #ced4da',
                  outline: 'none',
                }}
                required
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className={classes.maping__modalSubmitOuter}>
              <button type='submit' className={classes.maping__modalSubmitButton}>
                {isLoading && (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                )}
                Submit
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default UserLimitModal
