import {Modal} from 'react-bootstrap'
import {
  useChangeUserStatusMutation,
  useUpdateCompanyAdminMutation,
} from '../../../../services/companyServiceApi'
import classes from '../MembersArea.module.scss'

import {toast} from 'react-toastify'

interface AreyouSureModalProps {
  showAreSureModal: any
  setShowAreSureModal: React.Dispatch<React.SetStateAction<any>>
  areYouSureType: any
  areYouSureMessage: any
  areYouSureId: any
  areYouSureStatus: any
  companyData: any
  setTriggerStatusChange?: any
}

const AreyouSureModal: React.FC<AreyouSureModalProps> = ({
  showAreSureModal,
  setShowAreSureModal,
  areYouSureType,
  areYouSureStatus,
  areYouSureMessage,
  areYouSureId,
  companyData,
  setTriggerStatusChange = () => {},
}) => {
  const [changeUserStatus] = useChangeUserStatusMutation()
  const [updateAdmin] = useUpdateCompanyAdminMutation()

  const deactive = () => {
    changeUserStatus({id: areYouSureId, status: areYouSureStatus})
      .unwrap()
      .then(() => {
        toast.success(`User status updated`)
      })
      .catch((error) => {
        if (error.status === 400) {
          toast.error(error.data.message)
        }
      })

    setShowAreSureModal(false)
    setTriggerStatusChange(Date.now())
  }

  const makeAdmin = () => {
    updateAdmin({id: companyData?.id, admin_id: areYouSureId})
    setShowAreSureModal(false)
    toast.success(`Admin status updated`)
  }

  return (
    <Modal show={showAreSureModal} centered>
      <Modal.Header
        style={{
          border: 'none',
        }}
      >
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>{areYouSureMessage}</Modal.Body>
        <Modal.Footer
          style={{
            padding: '1rem',
            borderTop: 'none',
          }}
        >
          <div className={classes.maping__modalSubmitOuter}>
            <button
              type='button'
              className={classes.maping__modalCloseButton}
              onClick={() => setShowAreSureModal(false)}
            >
              Close
            </button>
          </div>
          <div className={classes.maping__modalSubmitOuter}>
            <button
              type='button'
              onClick={areYouSureType === 'DEACTIVE' ? deactive : makeAdmin}
              className={classes.maping__modalSubmitButton}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AreyouSureModal
