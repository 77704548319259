import {useState, type FC} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from '../../../app/modules/auth'
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from '../../../services/activityServiceApi'
import DelIcon from '../icons/homepage/DelIcon'
import EditIcon from '../icons/homepage/EditIcon'
import classes from './RecentActivity.module.scss'
import {Table} from 'react-bootstrap'

interface RecentActivityAreaProps {}

const RecentActivityArea: FC<RecentActivityAreaProps> = () => {
  const {currentUser} = useAuth()
  const {data: {activities = []} = {}, isLoading} = useGetActivitiesQuery({
    user_id: currentUser?.id,
    page: 1,
  })

  const [deleteAcitivityId, setDeleteAcitivityId] = useState<string>('')
  const [deleteAcitivity] = useDeleteActivityMutation()
  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleDelete = (uuid: string) => {
    deleteAcitivity(uuid)
      .unwrap()
      .then(() => {
        toast.success('Activity deleted ✓')
      })
      .catch(() => {
        toast.error('Failed to delete activity')
      })
  }
  const handleCancel = () => {
    setDeleteAcitivityId('')
  }
  const handleConfirm = () => {
    handleDelete(deleteAcitivityId)
    setDeleteAcitivityId('')
  }
  const rtf1 = new Intl.RelativeTimeFormat('en', {style: 'short'})
  const formatDate = (date: string) => {
    const dateToFormat = new Date(date)
    const daysAgo = rtf1.format(Math.floor((Date.now() - dateToFormat.getTime()) / 86400000), 'day')
    const hoursAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 3600000),
      'hour'
    )
    const minutesAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 60000),
      'minute'
    )
    const monthsAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 2628000000),
      'month'
    )
    const yearsAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 31536000000),
      'year'
    )
    const f1 =
      yearsAgo === 'in 0 yr.'
        ? monthsAgo === 'in 0 mo.'
          ? daysAgo === 'in 0 days'
            ? hoursAgo === 'in 0 hr.'
              ? minutesAgo
              : hoursAgo
            : daysAgo
          : monthsAgo
        : yearsAgo
    const f2 = f1.replace('in ', '').replace('.', '') + ' ago'
    const timeNum = parseInt(f2.split(' ')[0])
    const mf = timeNum > 1 ? 'minutes' : 'minute'
    const hf = timeNum > 1 ? 'hours' : 'hour'
    const monthf = timeNum > 1 ? 'months' : 'month'
    const yearf = timeNum > 1 ? 'years' : 'year'
    const f3 = f2.replace('hr', hf).replace('min', mf).replace('mo', monthf).replace('yr', yearf)
    return f3 === '0 minute ago' ? 'Just now' : f3
  }
  const tableRows = activities
    .map(
      (item: {
        id: number
        header: string
        title: string
        uuid: string
        updatedAt: string
        url: string
        questions: {
          requiredQuestion1: string
        }
      }) => {
        return (
          <tr
            key={item.id}
            style={{
              borderBottom: '1px solid #f1f1f1',
              height: '50px',
            }}
          >
            <td
              style={{
                width: '250px',
              }}
            >
              {item.questions.requiredQuestion1.slice(0, 20)}{' '}
              {item.questions.requiredQuestion1.length > 20 ? '...' : ''}
            </td>
            <td
              style={{
                width: '350px',
                textAlign: 'start',
              }}
            >
              {item.title}
            </td>
            <td>{item.header}</td>
            <td
              style={{
                width: '250px',
                textAlign: 'start',
              }}
            >
              {formatDate(item.updatedAt)}
            </td>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '22px',
              }}
            >
              {item.header === 'Image Generation' ? (
                <Link to={`/${item.url}/?uuid=${item.uuid}`}>
                  <EditIcon className={classes.svg} />
                </Link>
              ) : (
                <Link
                  to={`/${item.header.replace(' ', '-').toLowerCase()}/${item.url}/?uuid=${
                    item.uuid
                  }`}
                  title='Go to your activity'
                >
                  <EditIcon className={classes.svg} />
                </Link>
              )}
              <p
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleDelete(item.uuid)}
                title='Delete your activity'
              >
                <DelIcon className={classes.svg} />
              </p>
            </td>
          </tr>
        )
      }
    )
    .slice(0, 4)

  return (
    <div className={classes.recent}>
      <h3 className={classes.recent__title}>Recent Activity</h3>
      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Workflow</th>
              <th>Category</th>
              <th>Last Opened</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>
    </div>
  )
}

export default RecentActivityArea
