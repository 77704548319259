import {useRef, useState, type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import InviteColleague from '../homepage/InviteColleague'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './ReferralMembersArea.module.scss'

import moment from 'moment'
import {useGetReferredUsersQuery} from '../../../services/referServiceApi'
import {Table} from 'react-bootstrap'

interface ReferralMembersAreaProps {}

const ReferralMembersArea: FC<ReferralMembersAreaProps> = () => {
  const {currentUser} = useAuth()

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [page, setPage] = useState(1)
  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const {data: {users: refferedUsers = [], hasMore = false} = {}} = useGetReferredUsersQuery({
    id: currentUser?.id,
    page,
  }) as any

  const tableRows = refferedUsers?.map((item: any = {}) => {
    const {
      id,
      is_purchased,
      name,
      subscription_name,
      subscription_status,
      purchased_at,
      registered_at,
      avatar,
      email,
      created_at,
    } = item
    return (
      <tr key={id}>
        <td>
          <img
            style={{width: '36px', borderRadius: '50%'}}
            src={avatar || '/new-design/image/avatar.png'}
            alt='member'
          />
          <span className='m-2'>{name}</span>
        </td>
        <td>
          <p>{email}</p>
        </td>
        {/* <td
          style={{
            textAlign: 'center',
          }}
        >
          {is_purchased ? <GiCheckMark /> : <RxCross1 />}
        </td> */}

        <td
          style={{
            textAlign: 'center',
          }}
        >
          <p>{registered_at ? moment(registered_at).format('MM/DD/YYYY') : '-'}</p>
        </td>
        <td
          style={{
            textAlign: 'center',
          }}
        >
          <p>{purchased_at ? moment(purchased_at).format('MM/DD/YYYY') : '-'}</p>
        </td>
      </tr>
    )
  })

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }
  const myRef = useRef<HTMLDivElement | null>(null)
  return (
    <div className={classes.referral_members} ref={myRef}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague />}
      />

      <div className={classes.referral_members__topBlock}>
        <div className={classes.referral_members__topBlock__headers}>
          <h2 className={classes.referral_members__topBlock__headers__title}>Referred users</h2>
        </div>
      </div>
      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
            borderBottom: '1px solid #fff',
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Register Date
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Purchase Date
              </th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={7}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default ReferralMembersArea
