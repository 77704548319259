// apiSlice.js
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import * as authHelper from '../app/modules/auth/core/AuthHelpers'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const userServiceApi = createApi({
  reducerPath: 'user',
  baseQuery: refreshBaseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query({
      queryFn: async (query) => {
        const response = await fetch(`${API_URL}/user_all?${query}`)
        if (!response.ok) {
          throw new Error('Failed to fetch users')
        }
        return response.json()
      },
    }),
    getUserById: builder.query({
      query: (id) => {
        return {url: `/user/${id}`}
      },
    }),
    // getUserById: builder.query({
    //   queryFn: async (id) => {
    //     const response = await fetch(`${API_URL}/user/${id}`)
    //     if (!response.ok) {
    //       throw new Error('Failed to fetch user by id')
    //     }
    //     return response.json()
    //   },
    // }),
    createUser: builder.mutation({
      query: (user) => ({
        url: '/user/create',
        method: 'PUT',
        body: user,
      }),
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/user/${user.id}`,
        method: 'PATCH',
        body: user,
      }),
    }),
    setCompany: builder.mutation({
      query: (user) => ({
        url: `/user/set_company/${user.id}`,
        method: 'PATCH',
        body: user,
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: 'DELETE',
      }),
    }),
    completeSurvey: builder.mutation({
      query: (survey) => ({
        url: '/onboard-survey',
        method: 'POST',
        body: survey,
      }),
    }),
    getAllUsers: builder.query({
      query: ({page, filterType, keywords, selectType, triggerSearch}) => ({
        url: `${API_URL}/user?page=${page ? page : ''}&filterType=${
          filterType ? filterType : ''
        }&selectType=${selectType ? selectType : ''}&keywords=${keywords ? keywords : ''}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useSetCompanyMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useCompleteSurveyMutation,
} = userServiceApi
