import {FC, useEffect, useState} from 'react'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import navData from '../data/navs/navData'
// import {ReactComponents as ProfileIcon} from ''
import {Offcanvas} from 'react-bootstrap'
import {useAuth} from '../../app/modules/auth'
import {useLazyServerLogoutQuery} from '../../services/authServiceApi'
import UserIcon from './icons/navbar/UserIcon'
import ArrowUpIcon from './icons/navbar/ArrowUpIcon'
import LogoutIcon from './icons/navbar/LogoutIcon'
import InfoIcon from './icons/navbar/InfoIcon'
import {isBetaUser, isKlevereBetaUser, isSuperAdmin} from '../../utils/userRoles'
import {SubscriptionPlansEnum} from '../../types/types'

const Header: FC = () => {
  const activeNavColor = '#2468FF'
  const standartNavColor = '#616161'
  const headerWideClass = 'headerWide'

  const {currentUser, logout, auth, sidebarOpen, toggleSidebar} = useAuth()
  const [serverLogout] = useLazyServerLogoutQuery()
  const currentRoute = useLocation()
  const [headerClass, setHeaderClass] = useState<any>(undefined)
  const [isVisibleProfileMenu, setIsVisibleProfileMenu] = useState<boolean>(false)
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
  const [isMouseOn, setIsMouseOn] = useState(false)

  const navigate = useNavigate()

  const handleHover = (hover = true) => {
    const mouseOn = hover ? true : false
    if (currentRoute.pathname !== '/' && currentRoute.pathname !== '/upgrade-plan') {
      const value = hover ? headerWideClass : undefined
      setIsVisibleProfileMenu(false)
      setHeaderClass(value)
    }
    setIsMouseOn(mouseOn)
  }

  const handleVisibleProfileMenu = (isVisible: boolean = true) => {
    setIsVisibleProfileMenu(isVisible)
  }

  const navBlock = navData.map((item) => {
    const {id: groupId, groupName, navs} = item
    const itemBlock = navs.map((unit) => {
      const {id, name, Img, disabled, url, parentRoute} = unit
      return (
        <>
          {(!isKlevereBetaUser(currentUser?.email) && name === 'Real Estate') ||
          (isKlevereBetaUser(currentUser?.email) && name !== 'Real Estate') ? null : (
            <li key={unit.id}>
              {!disabled ? (
                <NavLink
                  to={parentRoute ? `${parentRoute}/${url}` : url}
                  key={id}
                  className='topBlock__nav_ul_li_a'
                >
                  {({isActive}) => (
                    <>
                      <Img color={isActive ? activeNavColor : standartNavColor} className='svg' />
                      <span>
                        {name}
                        {name === 'Finance' && <sup className='universalComingSoon'>Beta</sup>}
                      </span>
                    </>
                  )}
                </NavLink>
              ) : (
                <div className='topBlock__nav_ul_li_a'>
                  <Img color={standartNavColor} className='svg' />
                  <span>
                    {name} <sup className='universalComingSoon'>Coming soon</sup>
                  </span>
                </div>
              )}
            </li>
          )}
        </>
      )
    })
    return (
      <div
        key={groupId}
        className={`topBlock__nav__group ${groupName === 'history' ? 'mt-4' : ''}`}
      >
        {isKlevereBetaUser(currentUser?.email) && groupName !== 'workflows' ? null : (
          <h4 className='topBlock__nav__group__title'>{groupName}</h4>
        )}
        <ul>{itemBlock}</ul>
      </div>
    )
  })
  const mobileNavBlock = navData.map((item, index) => {
    const {id: groupId, groupName, navs} = item
    const itemBlock = navs.map((unit) => {
      const {id, name, Img, disabled, url, parentRoute} = unit
      return (
        <>
          {(!isKlevereBetaUser(currentUser?.email) && name === 'Real Estate') ||
          (isKlevereBetaUser(currentUser?.email) && name !== 'Real Estate') ? null : (
            <li
              key={index}
              style={{
                alignSelf: 'flex-start',
              }}
            >
              {!disabled ? (
                <NavLink
                  to={parentRoute ? `${parentRoute}/${url}` : url}
                  key={id}
                  className='topBlock__nav_ul_li_a'
                >
                  {({isActive}) => (
                    <>
                      {name === 'Real Estate' ? null : (
                        <Img color={isActive ? activeNavColor : standartNavColor} className='svg' />
                      )}

                      <p>{name}</p>
                    </>
                  )}
                </NavLink>
              ) : (
                <div className='topBlock__nav_ul_li_a'>
                  <Img color={standartNavColor} className='svg' />
                  <p>
                    {name} <sup className='universalComingSoon'>Coming soon</sup>
                  </p>
                </div>
              )}
            </li>
          )}
        </>
      )
    })
    return (
      <div
        key={groupId}
        className={`topBlock__nav__group ${groupName === 'history' ? 'mt-4' : ''}`}
      >
        <h4 className='topBlock__nav__group__title'>{groupName}</h4>
        <ul>{itemBlock}</ul>
      </div>
    )
  })
  useEffect(() => {
    setIsVisibleProfileMenu(false)
    if (currentRoute.pathname === '/' || currentRoute.pathname === '/upgrade-plan') {
      setHeaderClass(headerWideClass)
    } else {
      !isMouseOn && setHeaderClass(undefined)
    }
  }, [currentRoute])

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener('resize', () => {
        setScreenWidth(window.innerWidth)
      })
    }
  }, [])
  if (screenWidth < 1028) {
    return (
      <div
        style={{
          width: 100,
        }}
      >
        <Offcanvas
          show={sidebarOpen}
          onHide={toggleSidebar}
          className='custom-offcanvas-width'
          style={{
            maxWidth: '50%',
          }}
        >
          <Offcanvas.Body className={`header w-50 bg-white`}>
            <div className='topBlock'>
              <img className='w-75' src='../../new-design/image/logo.png' alt='logo' />
              <nav className='topBlock__nav'>{mobileNavBlock}</nav>
            </div>
            <div className='w-100'>
              <div
                className=''
                onMouseEnter={() => handleVisibleProfileMenu(true)}
                onClick={() => {
                  handleVisibleProfileMenu(!isVisibleProfileMenu)
                }}
              >
                <div
                  className={`bottomBlock__user__profile ${
                    isVisibleProfileMenu && 'bottomBlock__user__profileVisible'
                  }`}
                  style={{
                    width: '100%',
                    position: 'relative',
                    top: '-5px',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                  onMouseLeave={() => handleVisibleProfileMenu(false)}
                >
                  <NavLink to='profile' className='bottomBlock__user__profile__navLink'>
                    <UserIcon width='2rem' height='2rem' />
                    <span className='bottomBlock__user__profile__navLink__text'>
                      Profile settings
                    </span>
                  </NavLink>

                  <div
                    className='bottomBlock__user__profile__navLink'
                    onClick={() => {
                      navigate('profile', {state: {tab: 'referral'}})
                    }}
                  >
                    <ArrowUpIcon width='2rem' height='2rem' />
                    <span className='bottomBlock__user__profile__navLink__text'>Refer & earn</span>
                  </div>
                  <a
                    href='https://intercom.help/klevereai/en/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='bottomBlock__user__profile__navLink'
                  >
                    <InfoIcon width='2rem' height='2rem' />
                    <span className='bottomBlock__user__profile__navLink__text'>Help center</span>
                  </a>

                  <div
                    className='bottomBlock__user__profile__navLink'
                    onClick={() => {
                      serverLogout(auth?.access_token)
                      logout()
                    }}
                  >
                    <LogoutIcon width='2rem' height='2rem' />
                    <span className='bottomBlock__user__profile__navLink__text'>Log Out</span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 'wrap',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <img
                    className='w-25'
                    src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`}
                    alt='user'
                  />

                  <div className=''>
                    <h5 className=''>{`${currentUser?.first_name} ${currentUser?.last_name}`}</h5>
                    <address>{currentUser?.email || ''}</address>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    )
  }
  return (
    <header
      className={`header ${headerClass}`}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => {
        handleHover(false)
        setOpenProfileMenu(false)
      }}
    >
      <div className='topBlock'>
        <div className='topBlock__logo'>
          <img src='../../new-design/image/logo.png' alt='logo' />
        </div>
        <nav className='topBlock__nav'>{navBlock}</nav>
      </div>

      <div className='bottomBlock'>
        <div
          className='bottomBlock__user'
          onMouseEnter={() => handleVisibleProfileMenu(true)}
          onClick={() => {
            handleVisibleProfileMenu(!isVisibleProfileMenu)
          }}
        >
          <div
            className={`bottomBlock__user__profile ${
              isVisibleProfileMenu && 'bottomBlock__user__profileVisible'
            }`}
            onMouseLeave={() => handleVisibleProfileMenu(false)}
          >
            {isKlevereBetaUser(currentUser?.email) ? null : (
              <>
                <NavLink to='profile' className='bottomBlock__user__profile__navLink'>
                  <UserIcon width='2rem' height='2rem' />
                  <span className='bottomBlock__user__profile__navLink__text'>
                    Profile settings
                  </span>
                </NavLink>
                {(isSuperAdmin(currentUser?.email) || isBetaUser(currentUser?.role)) && (
                  <div
                    className='bottomBlock__user__profile__navLink'
                    onClick={() => {
                      navigate('profile', {state: {tab: 'referral'}})
                    }}
                  >
                    <ArrowUpIcon width='2rem' height='2rem' />
                    <span className='bottomBlock__user__profile__navLink__text'>Refer & earn</span>
                  </div>
                )}
                <a
                  href='https://intercom.help/klevereai/en/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bottomBlock__user__profile__navLink'
                >
                  <InfoIcon width='2rem' height='2rem' />
                  <span className='bottomBlock__user__profile__navLink__text'>Help center</span>
                </a>
              </>
            )}

            <div
              className='bottomBlock__user__profile__navLink'
              onClick={() => {
                serverLogout(auth?.access_token)
                logout()
              }}
            >
              <LogoutIcon width='2rem' height='2rem' />
              <span className='bottomBlock__user__profile__navLink__text'>Log Out</span>
            </div>
          </div>

          <div className='bottomBlock__user__info'>
            <div className='bottomBlock__user__info__imgBlock'>
              <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
            </div>
            <div className='bottomBlock__user__info__card'>
              <h3 className='bottomBlock__user__info__card__name'>
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
              </h3>
              <address>{currentUser?.email || ''}</address>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
