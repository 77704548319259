import type {FC} from 'react'
import templatesData from '../../data/marketing/marketingTemplatesData'
import CombinedWorkflow from '../universal-components/combined-workflow/CombinedWorkflow'

interface MarketingPageProps {}

const MarketingPage: FC<MarketingPageProps> = () => {
  const title = 'Marketing'
  const buttonColor = '#9DC8EE'

  return <CombinedWorkflow title={title} buttonColor={buttonColor} itemsData={templatesData} />
}

export default MarketingPage
