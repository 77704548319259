import {type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import classes from './Referral.module.scss'

import {toast} from 'react-toastify'
import {Tooltip} from 'react-tooltip'
import {useGetMembersStatQuery} from '../../../services/referServiceApi'
import CopyIcon from '../icons/chat/CopyIcon'
import ReferralMembersArea from './ReferralMembersArea'
import ReceiptItem from '../icons/referral/DollarSquare'
import DollarSquare from '../icons/referral/ReceiptItem'
import DocumentText from '../icons/referral/DocumentText'

const generateReferLink = (code?: string) => {
  if (!code) {
    return ''
  }
  var url = process.env.REACT_APP_FRONTEND_URL + `/auth/registration?referral_code=${code}`

  return url
}

interface ReferStatsProps {
  purchsaedUsers: number
  referral_code: string
  copyReferLink: () => void
  registered: number
  earnings: number
}

const ReferStats: FC<ReferStatsProps> = ({
  purchsaedUsers,
  referral_code,
  registered,
  earnings,
  copyReferLink,
}) => {
  const stats = [
    {
      id: 'registered',
      name: 'Registered',
      content: registered,
      tooltip: 'Total number of registered users',
      icon: <DocumentText className='svg' />,
    },
    {
      id: 'purchased',
      name: 'Purchased',
      content: purchsaedUsers,
      tooltip: 'Total number of purchased users',
      icon: <ReceiptItem className='svg' />,
    },
    {
      id: 'earnings',
      name: 'Earnings',
      content: earnings,
      tooltip: 'Total earnings from referral program',
      icon: <DollarSquare className='svg' />,
    },
    {
      id: 'code',
      name: 'Referral Code',
      content: referral_code,
      tooltip: 'Your referral code',
      icon: '',
    },
  ]
  const blocks = stats.map((item, idx) => {
    const {id, name, content, tooltip, icon} = item
    return (
      <div className='p-3  col-md-6 col-lg-3' key={id}>
        <div className={`${classes.quickAccess__row__item}`}>
          {id === 'code' ? (
            <>
              <span className={classes.quickAccess__row__item__description}>{name}</span>
              <div
                // className={classes.info}
                style={{
                  border: '2px solid rgba(40, 40, 40, 0.2)',
                  borderRadius: '10px',
                  width: '100%',
                  height: '4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 1rem',
                  marginTop: '.5rem',
                }}
              >
                <input
                  type={'text'}
                  value={
                    content ? '•••• •••• •••• ' + content.toString().slice(-4) : '•••• •••• ••••'
                  }
                  placeholder={'Refer Code'}
                  disabled
                  className='input-tooltip'
                  style={{
                    border: 'none',
                    height: '100%',
                    fontSize: '1.5rem',
                    background: 'none',
                    width: '100%',
                  }}
                />
                <button
                  onClick={copyReferLink}
                  style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    padding: '0.5rem',
                  }}
                >
                  <CopyIcon />
                </button>
                <Tooltip anchorSelect='.input-tooltip' place='top'>
                  {tooltip}
                </Tooltip>
              </div>
            </>
          ) : (
            <>
              <Tooltip anchorSelect={`.${name}`} place='top'>
                {tooltip}
              </Tooltip>
              <div className='info-container d-flex align-items-center gap-3'>
                <div
                  className={classes.quickAccess__row__item__icon_box}
                  style={{
                    background:
                      idx === 0
                        ? '#9dc8ee'
                        : idx === 1
                        ? '#8fd7bd'
                        : idx === 2
                        ? '#e4ed96'
                        : '#ffaf95',
                  }}
                >
                  {icon}
                </div>
                <div>
                  <span className={classes.quickAccess__row__item__description}>{name}</span>
                  <h2 className={`${name} `}>{content}</h2>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  })

  return (
    <div className={classes.quickAccess}>
      <div className='container-fluid'>
        <h3 className={`${classes.quickAccess__title} mb-4`}>Referral stats</h3>
        <div className={`row`}>{blocks}</div>
      </div>
    </div>
  )
}

const ReferralProgram = () => {
  const {currentUser} = useAuth()
  const {data: {purchsaedUsers = 0, totalUsers = 0, points = 0} = {}} = useGetMembersStatQuery(
    currentUser?.id
  )

  const copyReferLink = async () => {
    if (generateReferLink(currentUser?.referral_code)) {
      const link = generateReferLink(currentUser?.referral_code)
      navigator.clipboard.writeText(link)
      toast.success('Referral code copied successfully!')
    } else {
      toast.warn('No content found')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3rem',
      }}
      className={classes.referral}
    >
      <ReferStats
        purchsaedUsers={purchsaedUsers}
        referral_code={currentUser?.referral_code as string}
        copyReferLink={copyReferLink}
        registered={totalUsers}
        earnings={points}
      />
      <ReferralMembersArea />
    </div>
  )
}

export default ReferralProgram
