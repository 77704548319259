import {type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {useCreateApiKeyMutation, useGetApiKeyQuery} from '../../../services/apiKeyService'
import classes from './ApiGenerate.module.scss'

import {toast} from 'react-toastify'
import CopyIcon from '../icons/chat/CopyIcon'
import RefreshIcon from '../icons/chat/RefreshIcon'

interface ApiGenerateProps {}

const ApiGenerate: FC<ApiGenerateProps> = () => {
  const {currentUser} = useAuth()

  const {data, isLoading: getApiLoading} = useGetApiKeyQuery(currentUser?.id)
  const [createApiKey, {isLoading}] = useCreateApiKeyMutation()

  const generateApiKey = async () => {
    try {
      await createApiKey(currentUser?.id)
      toast.success('your API key updated')
    } catch (error) {
      toast.warning('sorry, something went wrong')
    }
  }
  const copyApiKey = async () => {
    navigator.clipboard.writeText(data?.api_key)
    toast.success('API key copied')
  }

  return (
    <div className={classes.apikey}>
      <div className={classes.apikey__container}>
        <div className={classes.apikey__container__topBlock}>
          <h2 className={classes.apikey__container__topBlock__title}>API key</h2>
          <h6>
            Keep your API key private. Avoid sharing it with others or exposing it in client-side
            code or the browser.
          </h6>
        </div>
        <div className={classes.info}>
          <input
            type={'text'}
            name={'api_key'}
            value={data?.api_key}
            placeholder={'Generate your API key...'}
            disabled
          />
          <div className={classes.info__btnBlock}>
            <button onClick={copyApiKey}>
              {' '}
              <CopyIcon className={classes.info__svg} />{' '}
            </button>
            <button onClick={generateApiKey}>
              {
                <div className={(isLoading || getApiLoading) && classes.info__icon}>
                  <RefreshIcon className={classes.info__svg} />
                </div>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiGenerate
