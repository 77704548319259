import {useState, type FC, useEffect} from 'react'
import classes from './GeneralInfoArea.module.scss'
import inputsGeneralData from '../../data/profile/inputsProfileData'
import {useAuth} from '../../../app/modules/auth'
import {useLazyGetUserByTokenQuery} from '../../../services/authServiceApi'
import {useUpdateUserMutation} from '../../../services/userServiceApi'
import AWS from 'aws-sdk'
import {toast} from 'react-toastify'
import axios from 'axios'
import {FiEye, FiEyeOff} from 'react-icons/fi'

interface GeneralInfoBlockProps {}

interface IFormInputs {
  id: number
  avatar: string
  first_name: string
  last_name: string
  company_name: string
  email: string
  password: string
}

const GeneralInfoBlock: FC<GeneralInfoBlockProps> = () => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  })
  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
  const REGION = process.env.REACT_APP_AWS_REGION
  const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION,
  })

  const message = {updated: 'Updated!', declined: 'Declined!'}

  const {currentUser, setCurrentUser} = useAuth()
  const access_token = useAuth().auth?.access_token
  const [getUserByToken] = useLazyGetUserByTokenQuery()
  const [updateUser] = useUpdateUserMutation()

  const [inputs, setInputs] = useState<IFormInputs | any>({
    id: currentUser?.id || 0,
    avatar: '',
    first_name: currentUser?.first_name || 'First Name',
    last_name: currentUser?.last_name || 'Last Name',
    company_name: currentUser?.company_name || 'Company Name',
    email: currentUser?.email || 'Email',
    password: '',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false)
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [uploadedAvatarUrl, setUploadedAvatarUrl] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    console.log('uploadedAvatarUrl', uploadedAvatarUrl)
  }, [uploadedAvatarUrl])

  const handleMessageVisiability = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
      setIsLoading(false)
    }, 2000)
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (e.target.files !== null && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      axios
        .post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData)
        .then((res: any) => {
          if (res.data.success === true) {
            const fileLink = res.data.location
            setUploadedAvatarUrl(fileLink)
            setInputs((prev: any) => ({...prev, [name]: fileLink}))
          }
        })
        .catch((e: any) => {
          if (e.response.data.message) {
            toast.error('Please provide a valid image')
          }
        })
    }
  }

  const handleInputs = (e: any) => {
    const value = e.target.value
    const name = e.target.name

    setInputs((prev: any) => ({...prev, [name]: value}))
  }

  const handleForm = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    const dataToUpdate: any = {...inputs}
    dataToUpdate.avatar === '' && delete dataToUpdate.avatar
    try {
      if (dataToUpdate.password === '') {
        delete dataToUpdate.password
      }

      await updateUser(dataToUpdate).unwrap()
      const user = await getUserByToken(access_token).unwrap()
      setCurrentUser(user)
      toast.success('Your information updated successfully!')
    } catch (err: any) {
      if (err.status === 400) {
        const message = err.data.message[0]
        toast.warning(message)
      }
    } finally {
      handleMessageVisiability()
    }
  }

  const inputsBlock = inputsGeneralData.map((item) => {
    const {id, title, name, placeholder, type, disabled} = item
    return (
      <label key={id} className={classes.info__form__centralBlock__inputsBlock__label}>
        <h4 className={classes.info__form__centralBlock__inputsBlock__label__title}>{title}</h4>

        {name === 'password' ? (
          <>
            <input
              type={showPassword ? 'text' : type}
              name={name}
              value={inputs[name]}
              placeholder={placeholder}
              disabled={disabled}
              onChange={handleInputs}
            />
            <span
              style={{
                position: 'absolute',
                top: '70%',
                right: '8px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
            </span>
          </>
        ) : (
          <input
            type={type}
            name={name}
            value={inputs[name]}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleInputs}
          />
        )}
      </label>
    )
  })

  return (
    <div className={classes.info}>
      <form className={classes.info__form} onSubmit={handleForm}>
        <div className={classes.info__form__topBlock}>
          <h3 className={classes.info__form__topBlock__title}>General information</h3>
          <p>Manage and update your personal details</p>
        </div>

        <div className={classes.info__form__centralBlock}>
          <label className={classes.info__form__centralBlock__photoLabel}>
            <h4 className={classes.info__form__centralBlock__photoLabel__title}>
              General Photo (Supported Formats: .jpg, .png)
            </h4>
            <div className={classes.info__form__centralBlock__photoLabel__photoInput}>
              <div className={classes.info__form__centralBlock__photoLabel__photoInput__imgBlock}>
                <img
                  src={
                    uploadedAvatarUrl !== ''
                      ? uploadedAvatarUrl
                      : currentUser && currentUser.avatar
                      ? currentUser.avatar
                      : '/new-design/image/avatar.png'
                  }
                  alt='avatar'
                />
              </div>
              <label
                className={classes.info__form__centralBlock__photoLabel__photoInput__fileInput}
              >
                {inputs.avatar ? 'Change' : 'Upload'}
                <input type='file' name='avatar' onChange={uploadFile} />
              </label>
            </div>
          </label>
          <div className={classes.info__form__centralBlock__inputsBlock}>{inputsBlock}</div>
        </div>
        <span className={classes.info__form__btnBlock}>
          {/* <p
            className={
              isSuccessUpdate
                ? classes.info__form__btnBlock__successMessage
                : classes.info__form__btnBlock__errorMessage
            }
            style={{display: isVisibleMessage ? 'flex' : 'none'}}
          >
            {isSuccessUpdate ? message.updated : message.declined}
          </p> */}
          <button className='universalButton' disabled={isLoading}>
            Save changes
          </button>
        </span>
      </form>
    </div>
  )
}

export default GeneralInfoBlock
