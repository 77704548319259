import {useRef, useState} from 'react'
import {LimitNotification} from '../../universal-components/limit-notification/LimitNotification'
import classes from './ReportVisualizer.module.scss'
import ImagesForm from '../../images-page/ImagesForm'
import ReportForm from './ReportForm'
import financeTemplatesData from '../../../data/finance/financeTemplatesData'
import ChartContainer from './ChartContainer'
import ChartArea from './ChartArea'
import {Pie} from 'react-chartjs-2'

import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from 'react-component-export-image'
import DownloadIcon from '../../icons/images/DownloadIcon'
import ReactLoading from 'react-loading'

const ReportVisualizer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triggerRegenerate, setTriggerRegenerate] = useState<number | string>('')
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [error, setError] = useState('')
  const componentRef = useRef<HTMLDivElement | null>(null)

  const [reports, setReports] = useState({
    title: '',
    labels: [],
    datasets: [],
  })

  const [chartType, setChartType] = useState<string>('BAR_CHART')
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const handleMessageVisibility = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  const template: any = financeTemplatesData.templates.find((item: any) => {
    return item.url.includes('report-visualization')
  })

  const generateFileName = () => {
    const title = reports.title ? reports.title.replace(/ /g, '_') + '_' : 'Finance_Report_'
    const timestamp = Date.now()
    return `${title}${timestamp}`
  }

  const exportAs = (type: string) => {
    setIsDownloading(true)
    switch (type) {
      case 'PDF':
        exportComponentAsPDF(componentRef, {fileName: generateFileName()})
        break
      case 'PNG':
        exportComponentAsPNG(componentRef, {fileName: generateFileName()})
        break
      case 'JPEG':
        exportComponentAsJPEG(componentRef, {fileName: generateFileName()})
        break
      default:
        break
    }
    setTimeout(() => {
      setIsDownloading(false)
    }, 1000)
  }

  return (
    <div className={`universalPage ${classes.images}`}>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-lg-5 '>
            <div className={classes.images__leftBlock}>
              <div className={`universalTopBlock ${classes.images__leftBlock__universalTopBlock}`}>
                <h1 className='universalTopTitle'>Report Visualizer</h1>
                <div className='universalBreadcrumbs'>
                  <span>Home</span>
                  <span>/</span>
                  <span className='universalBreadcrumbs__lastChild'>Report Visualizer</span>
                </div>
              </div>
              <ReportForm
                setReports={setReports}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setChartType={setChartType}
                triggerRegenerate={triggerRegenerate}
                setMessage={setMessage}
                handleMessageVisiability={handleMessageVisibility}
                setError={setError}
                templateItem={template}
              />
            </div>
          </div>

          <div className='col-12 col-lg-7 mt-5 mt-lg-0'>
            {!isLoading && (
              <button onClick={() => exportAs('JPEG')} className={classes.downloadBtn}>
                {isDownloading ? (
                  <ReactLoading
                    type='spinningBubbles'
                    color='#fff'
                    height={'2rem'}
                    width={'2rem'}
                  />
                ) : (
                  <DownloadIcon />
                )}
              </button>
            )}

            <ChartArea
              chartType={chartType}
              reports={reports}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportVisualizer
