import {FC, useRef, useState} from 'react'
import useDalleRequest from '../../../hooks/useDalleRequest'
import hrTemplatesData from '../../../data/hr/hrTemplatesData'
import financeTemplatesData from '../../../data/finance/financeTemplatesData'
import {IWorkflowFormInputs} from '../../../../types/types'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import langsData from '../../../data/universal-data/langsData'
import variationsData from '../../../data/universal-data/variationsData'
import classes from '../../universal-components/combined-form/CombinedForm.module.scss'
import {CustomTooltip} from '../../universal-components/tooltip/Tooltip'
import socialMediaData from '../../../data/universal-data/socialMediaData'
import {toast} from 'react-toastify'
import axios from 'axios'

// const {handleForm} = useDalleRequest({
//     myRef,
//     axiosURL,
//     requiredQuestions,
//     additionalQuestions,
//     optionalSelect,
//     inputs,
//     setImages,
//     setIsLoading,
//     setMessage,
//     handleMessageVisiability,
//     setError,
//     handleSaveActivity,
//   })

interface ReportFormProps {
  setReports: Function
  isLoading?: boolean
  setIsLoading?: Function
  setChartType?: Function
  triggerRegenerate?: string | number
  setMessage: Function
  handleMessageVisiability: Function
  setError: Function
  templateItem: any
}

const ReportForm: FC<ReportFormProps> = ({
  triggerRegenerate,
  setReports = () => {},
  setIsLoading = () => {},
  setChartType = () => {},
  isLoading,
  setMessage = () => {},
  handleMessageVisiability = () => {},
  setError = () => {},
  templateItem,
}) => {
  const myRef = useRef<HTMLFormElement | null>(null)
  const [isVisibleAdditionalQuestion, setIsVisibleAdditionalQuestion] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false)

  const {
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    axiosURL,
    axiosHeader,
    relevanceRequest,
    easyRequest,
    imgGenerate,
    iceBreaker,
    formType,
  } = templateItem

  const [inputs, setInputs] = useState<IWorkflowFormInputs>({
    requiredQuestion1: '',
    requiredQuestion2: 'Bar Chart',
    file: '',
  })

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (e.target.files !== null && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      setIsUploading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData)
        .then((res: any) => {
          if (res.data.success === true) {
            const fileLink = res.data.location
            setInputs((prev: any) => ({...prev, [name]: fileLink}))
          }
        })
        .catch((e: any) => {
          if (e.response.data.message) {
            toast.error('Please provide a valid file')
          }
        })
        .finally(() => {
          setIsUploading(false)
          setIsLoading(false)
          setIsFileUploaded(true)
        })
    }
  }

  const handleVisibleAdditQuest = () => {
    setIsVisibleAdditionalQuestion((prev) => !prev)
  }

  const handleInputs = (e: any) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    const name = e.target.name

    setInputs((prev: any) => ({...prev, [name]: value}))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!inputs.file) {
      toast.error('Please upload a file')
      return
    }

    const params = {
      file: inputs.file,
      description: inputs.requiredQuestion1,
      chartType: inputs.requiredQuestion2,
    }

    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/gpt/generateVisualReport`, params)
      .then((res: any) => {
        let data = {
          title: res.data.visualReport.title,
          labels: res.data.visualReport.labels,
          datasets: [
            {
              label: '# Value',
              data: res.data.visualReport.datasets,
              backgroundColor: ['#2468ff', '#e4ed96', '#282828'],
              borderColor: ['#2468ff', '#e4ed96', '#282828'],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        }

        setReports(data)
      })
      .finally(() => {
        type ChartTypeKeys = 'Bar Chart' | 'Line Chart' | 'Pie Chart'

        const chartTypes: Record<ChartTypeKeys, string> = {
          'Bar Chart': 'BAR_CHART',
          'Line Chart': 'LINE_CHART',
          'Pie Chart': 'PIE_CHART',
        }

        const index: ChartTypeKeys = inputs.requiredQuestion2 as ChartTypeKeys

        const chartType = chartTypes[index] || 'BAR_CHART'

        setIsLoading(false)
        setChartType(chartType)
      })
  }

  return (
    <form ref={myRef} className={classes.form} onSubmit={handleSubmit}>
      <div className='col-12'>
        <h3 className={`${classes.form__label__title} mb-2`}>
          Upload File (CSV, Excel)<span className={classes.required}>*</span>
          <CustomTooltip
            tooltipText='Upload a Excel or Spreadsheet file'
            tooltipAnchor='file_mark'
          />
        </h3>
        <label className={classes.form__label__fileInput}>
          {!isFileUploaded ? '+ Upload File' : '+ Change File'}
          <input type='file' onChange={uploadFile} name='file' />
        </label>
      </div>

      <div className='col-12'>
        <label className={`${classes.form__label} mb-2`}>
          <h3 className={classes.form__label__title}>
            What would you like to visualize? <span className={classes.required}>*</span>
            <CustomTooltip
              tooltipText='Describe what would you like to visualize'
              tooltipAnchor='description_mark'
            />
          </h3>

          <textarea
            name='requiredQuestion1'
            onChange={handleInputs}
            required={true}
            placeholder='Enter'
          />
        </label>
      </div>

      {/* Trigger PULL */}

      <div className='col-12'>
        <div className='row'>
          <div className='col-6'>
            <label className={classes.form__label}>
              <h3 className={classes.form__label__title}>
                Visualization Type <span className={classes.required}>*</span>
                <CustomTooltip
                  tooltipText='Choose your visualization type'
                  tooltipAnchor='chartType_mark'
                />
              </h3>
              <div className={classes.form__label__selectBlock}>
                <select required={true} name='requiredQuestion2' onChange={handleInputs}>
                  <option value='Bar Chart' selected>
                    Bar Chart
                  </option>
                  <option value='Line Chart'>Line Chart</option>

                  <option value='Pie Chart'>Pie Chart</option>
                </select>
                <div className={classes.form__label__selectBlock__imgBlock}>
                  <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <button className='universalButton' disabled={isLoading || isUploading}>
        Generate
      </button>
    </form>
  )
}

export default ReportForm
