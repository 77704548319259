const headersProfileData = [
  {
    id: 1,
    name: 'General information',
    url: 'info',
  },

  {
    id: 2,
    name: 'Billing',
    url: 'billing',
  },

  {
    id: 3,
    name: 'Members',
    url: 'members',
  },
  {
    id: 4,
    name: 'API',
    url: 'api',
  },
  {
    id: 5,
    name: 'Refer & Earn',
    url: 'referral',
  },
  {
    id: 5,
    name: 'Onboard Survey',
    url: 'onboard_survey',
  },
  {
    id: 6,
    name: 'Users',
    url: 'users',
  },
  {
    id: 7,
    name: 'Brand Voice',
    url: 'brand_voice',
  },
]

export default headersProfileData
