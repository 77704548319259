import axios from 'axios'
import {useEffect, useState, type FC} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Tooltip} from 'react-tooltip'
import {useAuth} from '../../../app/modules/auth'
import {useGetBalanceQuery} from '../../../services/balanceServiceApi'
import {useGetPanInfoQuery} from '../../../services/companyServiceApi'
import {SubscriptionPlansEnum} from '../../../types/types'
import classes from './CurrentPlanBlock.module.scss'
import {isFreemiumUser, isTrialUser} from '../../../utils/userRoles'
interface CurrentPlanBlockProps {}

const CurrentPlanBlock: FC<CurrentPlanBlockProps> = () => {
  const {currentUser} = useAuth()
  const {data: planData, isLoading} = useGetPanInfoQuery(currentUser?.id)

  const [disableCancelBtn, setDisableCancelBtn] = useState(false)

  const isAdmin = currentUser?.role === 'ADMIN'

  const [plan, setPlan] = useState({
    name: '',
    validStatus: '',
    rows: [] as any,
  })

  const handleCancelSubscription = () => {
    setDisableCancelBtn(true)
    const API_URL = process.env.REACT_APP_API_URL
    axios
      .post(`${API_URL}/payment/cancel-subscription`, {
        userId: currentUser?.id,
      })
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          toast.success(res.data.message)
        } else {
          toast.warning(res.data.message)
        }
        setDisableCancelBtn(false)
      })
      .catch((e) => {
        setDisableCancelBtn(false)
        toast.error('Failed to cancel subscription')
      })
  }

  useEffect(() => {
    if (planData) {
      const {usage, plan, usageLimit, subscription_status} = planData
      const {words, images, credits} = usage

      const {
        words: wordsLimit,
        images: imagesLimit,
        credits: creditsLimit,
      } = usageLimit || {
        words: 0,
        images: 0,
        credits: 0,
      }
      const {name} = plan

      const rows = [
        {
          title: 'words',
          totalCount: wordsLimit,
          currentCount: words,
          disabled: false,
        },
        {
          title: 'images',
          totalCount: imagesLimit,
          currentCount: images,
          disabled: false,
        },
        {
          title: 'credits',
          totalCount: creditsLimit,
          currentCount: credits,
          disabled: false,
        },
      ]
      setPlan({
        name,
        validStatus: subscription_status,
        rows,
      })

      if (name === SubscriptionPlansEnum.FREE) {
        setDisableCancelBtn(isTrialUser(currentUser) || isFreemiumUser(currentUser))
      }
    }
  }, [planData, currentUser])

  const rowBlock = plan.rows.map((item: any, index: number) => {
    const {title, totalCount, currentCount, disabled} = item
    const count = currentCount / totalCount
    const progess = count > 1 ? '100%' : `${count * 100}%`

    return (
      !disabled && (
        <div key={index} className={classes.plan__chart__row__item}>
          <div className={classes.plan__chart__row__item__progress}>
            <div
              className={classes.plan__chart__row__item__progress__bar}
              style={{width: progess}}
            ></div>
          </div>
          <div className={classes.plan__chart__row__item__bottomBlock}>
            <span>
              {title === 'credits' && isFreemiumUser(currentUser)
                ? 'credits remaining in your plan'
                : title === 'credits' && plan.name === SubscriptionPlansEnum.FREE
                ? 'workflow credits (non-renewable in free plan)'
                : `${title} remaining in your plan this month`}
            </span>
            <span>
              {currentCount > 0 ? currentCount : 0} of {totalCount} Used
            </span>
          </div>
        </div>
      )
    )
  })
  const {data: balance} = useGetBalanceQuery(currentUser?.id)
  const balanceBlock = () => {
    const {
      words: totalWords = 0,
      images: totalImages = 0,
      credits: totalCredits = 0,
      //   points = 0,
    } = balance?.total || {}
    const {
      words: leftWords = 0,
      images: leftImages = 0,
      credits: leftCredits = 0,
      //points: leftPoints = 0,
    } = balance?.left || {}

    const wordUsedPercent = (totalWords - leftWords) / totalWords
    const wordProgress = wordUsedPercent > 1 ? '100%' : `${wordUsedPercent * 100}%`
    const imageUsedPercent = (totalImages - leftImages) / totalImages
    const imageProgress = imageUsedPercent > 1 ? '100%' : `${imageUsedPercent * 100}%`
    const creditUsedPercent = (totalCredits - leftCredits) / totalCredits
    const creditProgress = creditUsedPercent > 1 ? '100%' : `${creditUsedPercent * 100}%`

    //const pointUsedPercent = (points - leftPoints) / points
    //const pointProgress = pointUsedPercent > 1 ? '100%' : `${pointUsedPercent * 100}%`

    const rowBlock = [
      {
        title: 'words',
        totalCount: totalWords,
        currentCount: leftWords,
        progress: wordProgress,
      },
      {
        title: 'images',
        totalCount: totalImages,
        currentCount: leftImages,
        progress: imageProgress,
      },
      {
        title: 'credits',
        totalCount: totalCredits,
        currentCount: leftCredits,
        progress: creditProgress,
      },
    ]
    return (
      <>
        {rowBlock.map(({title, totalCount, currentCount, progress}: any) => (
          <div className={classes.plan__chart__row__item} key={title}>
            <div className={classes.plan__chart__row__item__progress}>
              <div
                className={classes.plan__chart__row__item__progress__bar}
                style={{width: progress}}
              ></div>
            </div>
            <div className={classes.plan__chart__row__item__bottomBlock}>
              <span>
                {currentCount} {title} remaining in your plan this month
              </span>
              <span>
                {totalCount - currentCount} of {totalCount} Used
              </span>
            </div>
          </div>
        ))}
      </>
    )
  }
  if (isLoading) return <div>Loading...</div>
  return (
    <div className={classes.plan}>
      <Tabs defaultActiveKey='plan' variant='tabs'>
        <Tab eventKey='plan' title='Current Plan' tabClassName='plan_tab p-3'>
          <div className={classes.plan__chart}>
            <div className={classes.plan__chart__topBlock}>
              <h3 className={classes.plan__chart__topBlock__title}>
                {isFreemiumUser(currentUser) ? 'Freemium Plan' : plan.name}
              </h3>

              {isFreemiumUser(currentUser) ? (
                <p>Your freemium plan is currently active</p>
              ) : (
                <p>
                  {plan.name === SubscriptionPlansEnum.FREE
                    ? `Your free plan is currently ${plan.validStatus}`
                    : `You are on ${plan.name} plan which is ${plan.validStatus}`}
                </p>
              )}
            </div>
            <div className={classes.plan__chart__row}>{rowBlock}</div>
          </div>
          <Tooltip anchorSelect='.plan_tab' place='top'>
            Your current plan details
          </Tooltip>
          {isAdmin && (
            <div className={`${classes.plan__btnBlock} mt-4`}>
              <button
                className={`universalButton bg-dark`}
                onClick={handleCancelSubscription}
                disabled={disableCancelBtn}
                title='Cancel subscription'
              >
                Cancel subscription
              </button>

              {currentUser?.is_appsumo ? (
                <a href='https://appsumo.com/account/products/' rel='noreferrer' target='_blank'>
                  <button className={`universalButton ${classes.plan__btnBlock__upgrade}`}>
                    Upgrade plan
                  </button>
                </a>
              ) : (
                <Link to='/upgrade-plan'>
                  <button className={`universalButton ${classes.plan__btnBlock__upgrade}`}>
                    Upgrade plan
                  </button>
                </Link>
              )}
            </div>
          )}
        </Tab>
        {!isFreemiumUser(currentUser) && !isTrialUser(currentUser) && (
          <Tab eventKey='balance' title='Balance' tabClassName='balance_tab p-3'>
            <div className={classes.plan__chart}>
              <div className={classes.plan__chart__topBlock}>
                <h3 className={classes.plan__chart__topBlock__title}>Available Balance</h3>
              </div>
              <div className={classes.plan__chart__row}>{balanceBlock()}</div>
            </div>
            <Tooltip anchorSelect='.balance_tab' place='top'>
              Your available balance details
            </Tooltip>
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default CurrentPlanBlock
