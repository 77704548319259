import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const hrTemplatesData = {
  workflows: [],
  templates: [
    {
      id: 5,
      header: 'Human resource',
      title: 'Job Description',
      description: 'Draw in quality talent with powerful job descriptions.',
      url: 'job-description',
      axiosURL: 'generateJobDescription',
      axiosHeader: 'jobDescription',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 600,
      },
      requiredQuestions: [
        {
          id: 11,
          title: 'What is the job title? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipAnchor: 'description_mark',
          tooltipText: 'Enter the job description you want to evaluate the candidate against.',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 9,
      header: 'Human resource',
      title: 'Hiring Research',
      description: 'Conduct research to meet your company’s hiring objectives.',
      url: 'hiring-research',
      axiosURL: 'generateHiringResearch',
      axiosHeader: 'research',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 200,
      },
      requiredQuestions: [
        {
          id: 21,
          title: 'What are the main points to cover?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'mainPoint',
          tooltipAnchor: 'point_mark',
          tooltipText:
            'E.g.: Which new skill set do I need for my e-commerce business? How many years of experience is considered an expert in data science? What is the average salary of a marketing manager in Germany?',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 3,
      header: 'Human resource',
      title: 'Performance Evaluation',
      description: 'Prepare personalized and insightful evaluations for employees.',
      url: 'performance',
      axiosURL: 'generatePerformanceEvaluation',
      axiosHeader: 'evaluation',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 500,
      },
      requiredQuestions: [
        {
          id: 31,
          title: 'What were the employee’s key contributions and/or achievements?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'contributions',
          tooltipAnchor: 'contributions_mark',
          tooltipText:
            'What specific goals were accomplished? Were there any outstanding contributions they made during the evaluation period?',
        },

        {
          id: 32,
          title: 'What are the areas for improvement or development?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'improvement',
          tooltipAnchor: 'title_mark',
          tooltipText:
            'Are there any performance gaps, skill deficiencies, or behaviors that need adjustment',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 4,
      header: 'Human resource',
      title: 'HR Policy Design',
      description: 'Create detailed policies in line with your company’s objectives.',
      url: 'policy',
      axiosURL: 'generatePolicyDesign',
      axiosHeader: 'policyDesign',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 700,
      },
      requiredQuestions: [
        {
          id: 41,
          title: 'What topic is this policy intended to address or resolve? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipAnchor: 'title_mark',
          tooltipText:
            'For example; equal employment opportunity policy, attendance & punctuality policy, remote work policy?',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 6,
      header: 'Human resource',
      title: 'Hire Letter',
      description: 'Create personalized hire letters to impress new employees.',
      url: 'hire-letter',
      axiosURL: 'generateHireLetter',
      axiosHeader: 'hireLetter',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 600,
      },
      requiredQuestions: [
        {
          id: 51,
          title: 'What is the job title?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipAnchor: 'title_mark',
          tooltipText:
            'Provide a clear and concise job title. E.g, Marketing Manager, Full stack developer, Web designer',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 7,
      header: 'Human resource',
      title: 'Termination Letter',
      description: 'Notify employees about their end of employment.',
      url: 'termination-letter',
      axiosURL: 'generateTerminationLetter',
      axiosHeader: 'terminationLetter',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 650,
      },
      requiredQuestions: [
        {
          id: 61,
          title: 'What is the job title? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipAnchor: 'title_mark',
          tooltipText:
            'Provide a clear and concise job title. E.g, Marketing Manager, Full stack developer, Web designer',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 8,
      header: 'Human resource',
      title: 'Recruitment Strategy',
      description: 'Draw up a winning strategy to attract best in class talent.',
      url: 'recruit-strategy',
      axiosURL: 'generateRecruitmentStrategy',
      axiosHeader: 'recruitmentStrategy',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      Img: OneLineIcon,
      formType: 'hr',
      limits: {
        words: 650,
      },
      requiredQuestions: [
        {
          id: 71,
          title: 'What are the specific talent needs of your company? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'talent',
          tooltipAnchor: 'talent_mark',
          tooltipText:
            'For example; we need 2 store managers with high-volume retail experience or we need 6 customer service reps with exceptional experience on phone, email and chat',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 1,
      header: 'Human resource',
      title: 'CV Screening',
      description: 'Auto screen CV’s to discover candidate fit.',
      Img: ThreeLinesIcon,
      url: 'screen-custom',
      axiosURL: 'generateCvScreening',
      axiosHeader: 'cvScreening',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 275,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 93,
          title: 'CV/Resume PDF File',
          type: 'upload',
          placeholder: '+ Upload File (.pdf,.doc,.docx)',
          axiosParamName: 'pdf_resume',
          tooltipAnchor: 'pdf_mark',
          tooltipText: "Upload a PDF of the candidate's resume/CV.",
        },

        {
          id: 94,
          title: 'Job Description',
          type: 'input',
          placeholder: 'Describe role..',
          axiosParamName: 'job_description',
          tooltipAnchor: 'description_mark',
          tooltipText: 'Enter the job description you want to evaluate the candidate against.',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 2,
      header: 'Human resource',
      title: 'Screen LinkedIn Profile',
      description: 'Auto screen LinkedIn profiles to discover candidate fit.',
      Img: ThreeLinesIcon,
      url: 'description-comparer-custom',
      axiosURL: 'generateLinkedinScreening',
      axiosHeader: 'linkedinScreening',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 369,
        credits: 14,
      },
      requiredQuestions: [
        {
          id: 95,
          title: 'LinkedIn URL ',
          type: 'input',
          placeholder: "Link to candidate's profile..",
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'url_mark',
          tooltipText: "Enter the candidate's LinkedIn profile URL",
        },

        {
          id: 96,
          title: 'Job Description',
          type: 'input',
          placeholder: 'Describe role for candidate....',
          axiosParamName: 'job_description',
          tooltipAnchor: 'description_mark',
          tooltipText: 'Enter the job description you want to evaluate the candidate against.',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
  ],
}

export default hrTemplatesData
