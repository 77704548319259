import FinanceIcon from '../../components/icons/homepage/FinanceIcon'
import HumanResourceIcon from '../../components/icons/homepage/HumanResourceIcon'
import MarketingIcon from '../../components/icons/homepage/MarketingIcon'
import SalesIcon from '../../components/icons/homepage/SalesIcon'

const arr = [
  {
    id: 1,
    name: 'Marketing',
    description: 'Create powerful brands and improve demand generation.',
    Img: MarketingIcon,
    disabled: false,
    url: 'marketing',
  },
  {
    id: 2,
    name: 'Sales',
    description: 'Improve lead research, close deals and hit monthly targets.',
    Img: SalesIcon,
    disabled: false,
    url: 'sales',
  },
  {
    id: 3,
    name: 'Human Resource',
    description: 'Build and retain high performing teams across all functions.',
    Img: HumanResourceIcon,
    disabled: false,
    url: 'human-resource',
  },
  {
    id: 4,
    name: 'Finance',
    description: 'Analyze financial data and improve decision making.',
    Img: FinanceIcon,
    disabled: true,
    url: 'finance',
  },

]

export default arr
