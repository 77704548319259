import {useEffect, useState, type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import headersProfileData from '../../data/profile/headersProfileData'
import ApiGenerate from './ApiGenerate'
import BillingArea from './BillingArea'
import GeneralInfoArea from './GeneralInfoArea'
import MembersArea from './MembersArea'
import classes from './ProfilePage.module.scss'
import ReferralProgram from './ReferralProgram'
import {useLocation, useNavigate} from 'react-router-dom'
import OnboardSurvey from './OnboardSurvey'
import {Button} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import Users from './Users'
import BrandVoice from './BrandVoice'

const getDefaultActiveTab = (location: any, navigate: any, defaultCell: string) => {
  if (location?.state?.tab === 'referral') {
    navigate('/profile')
    return 'referral'
  }
  if (headersProfileData[0].url) {
    return headersProfileData[0].url
  }
  return defaultCell
}

interface ProfilePageProps {}

const ProfilePage: FC<ProfilePageProps> = () => {
  const defaultCell = 'info'
  const {currentUser, toggleSidebar} = useAuth()
  const isAdmin = currentUser?.role === 'ADMIN'

  const isSuperAdmin =
    currentUser?.email === 'sarfaraz@mediusware.com' || currentUser?.email === 'danny@klevere.ai'

  const body: any = {
    info: <GeneralInfoArea />,
    billing: <BillingArea />,
    members: <MembersArea />,
    api: <ApiGenerate />,
    referral: <ReferralProgram />,
    onboard_survey: <OnboardSurvey />,
    users: <Users />,
    brand_voice: <BrandVoice />,
  }

  const location = useLocation()
  const navigate = useNavigate()

  const [activeCell, setActiveCell] = useState<string>(
    getDefaultActiveTab(location, navigate, defaultCell)
  )

  const handleCells = (name: string = defaultCell) => {
    setActiveCell(name)
  }

  useEffect(() => {
    if (location.state) handleCells(getDefaultActiveTab(location, navigate, activeCell))
  }, [location])
  const plan = currentUser?.subscription?.name
  const isPaidUser = () => {
    if (
      plan === 'Starter Monthly' ||
      plan === 'Starter Yearly' ||
      plan === 'Premium Monthly' ||
      plan === 'Premium Yearly' ||
      plan === 'AppSumo Tier 2' ||
      plan === 'AppSumo Tier 3'
    ) {
      return true
    }
    return false
  }
  const topCellsBlock = headersProfileData?.map((item) => {
    const {id, name, url} = item
    if (name === 'API' && !isSuperAdmin) {
      return null
    }
    if (name === 'Refer & Earn' && (!isAdmin || !isPaidUser())) {
      return null
    }
    if (name === 'Members' && (!isAdmin || !isPaidUser())) {
      return null
    }
    if (name === 'Onboard Survey' && !isSuperAdmin) {
      return null
    }
    if (name === 'Users' && !isSuperAdmin) {
      return null
    }

    if (name === 'Brand Voice' && !isSuperAdmin) {
      return null
    }

    return (
      <>
        <div
          key={id}
          className={`${classes.profile__table__top__cell} ${
            activeCell === url ? classes.profile__table__top__cellActive : undefined
          }`}
          onClick={() => handleCells(url)}
        >
          {name}
        </div>
      </>
    )
  })

  return (
    <div className={`universalPage ${classes.profile}`}>
      <div className='universalTopBlock'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <h1 className='universalTopTitle'>Profile</h1>
          <Button variant='dark' className={'navbar-toggler-btn '} onClick={() => toggleSidebar()}>
            <GiHamburgerMenu />
          </Button>
        </div>
        {/* <div className='universalBreadcrumbs'>
          <span>Profile</span>
          <span>/</span>
          <span className='universalBreadcrumbs__lastChild'>Profile</span>
        </div> */}
      </div>
      <div className={classes.profile__table}>
        <div className={classes.profile__table__top}>{topCellsBlock}</div>
        <div className={classes.profile__table__body}>{body[activeCell]}</div>
      </div>
    </div>
  )
}

export default ProfilePage
