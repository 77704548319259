export enum SubscriptionIntervalEnum {
  MONTH = 'Month',
  YEAR = 'Year',
  WEEK = 'Week',
  INFINITY = 'Month',
}

export enum FreemiumLimitEmum {
  WORDS = 2500,
  IMAGES = 10,
  CREDITS = 100,
}

export enum FreePlanEnum {
  WORDS = 10000,
  IMAGES = 10,
  CREDITS = 100,
}

export enum SubscriptionWordsLimitEnum {
  STARTER = 50000,
  PREMIUM = 250000,
  FREE = 10000,
  APPSUMOTIER1 = 10000,
  APPSUMOTIER2 = 40000,
  APPSUMOTIER3 = 120000,
}

export enum SubscriptionImagesLimitEnum {
  STARTER = 50,
  PREMIUM = 200,
  FREE = 5,
  APPSUMOTIER1 = 10,
  APPSUMOTIER2 = 25,
  APPSUMOTIER3 = 100,
}

export enum SubscriptionUsersLimitEnum {
  STARTER = 5,
  PREMIUM = 10,
  FREE = 1,
  APPSUMOTIER1 = 1,
  APPSUMOTIER2 = 5,
  APPSUMOTIER3 = 1000,
}

export enum SubscriptionCreditsLimitEnum {
  STARTER = 1000,
  PREMIUM = 5000,
  FREE = 100,
  APPSUMOTIER1 = 500,
  APPSUMOTIER2 = 1500,
  APPSUMOTIER3 = 5000,
}

export enum SelectTypeEnum {
  NAME = 'name',
  EMAIL = 'email',
  COMPANY = 'company_name',
}

export enum FilterTypeEnum {
  FREE = 'FREE_USERS',
  APPSUMO = 'APPSUMO_USERS',
  PAID = 'PAID_USERS',
  TOTAL = 'TOTAL_USERS',
}

export enum SubscriptionPlansEnum {
  STARTER_MONTHLY = 'Starter Monthly',
  PREMIUM_MONTHLY = 'Premium Monthly',
  STARTER_YEARLY = 'Starter Yearly',
  PREMIUM_YEARLY = 'Premium Yearly',
  FREE = 'Free Plan',
  APPSUMOTIER1 = 'AppSumo Tier 1',
  APPSUMOTIER2 = 'AppSumo Tier 2',
  APPSUMOTIER3 = 'AppSumo Tier 3',
}

export enum GlobalErrorMessagesEnum {
  SERVER_ERROR = 'Oops! There was an error. Please try again or contact support.',
}

export interface Template {
  id: number
  title: string
  description: string
  url: string
}

export interface TemplatesData {
  marketing: Template[]
  sales: Template[]
  hr: Template[]
  finance: Template[]
}

export type AnswerToEdit = {
  id: number
  text: any
}

export interface IWorkflowFormInputs {
  requiredQuestion1: string
  requiredQuestion2?: string
  requiredQuestion3?: string
  requiredQuestion4?: string
  requiredQuestion5?: string
  additionalQuestion1?: string
  additionalQuestion2?: string
  additionalQuestion3?: string
  optionalSelect1?: string
  optionalSelect2?: string
  optionalSelect3?: string
  optionalSelect4?: string
  optionalSelect5?: string
  language?: string
  variations?: number
  media?: string
  imagesInclude?: boolean
  file?: any
  iceBreakerInclude?: boolean
  linkedin_url?: string
}

export interface IImageFormData {
  id: number
  axiosURL: string
  axiosHeader: string
  requiredQuestions: {}[]
  additionalQuestions: {}[]
  optionalSelect: Object[]
}

export interface IUsageRateData {
  words: number[]
  credits: number[]
  images: number[]
  months: string[]
}

export interface IColumnsOrder {
  0: number
  1: number
  2: number
  3?: number
  4?: number
  5?: number
  6?: number
  7?: number
  8?: number
  9?: number
  10?: number
}
