import {useState, type FC, useEffect, useRef} from 'react'
import classes from './CombinedForm.module.scss'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import langsData from '../../../data/universal-data/langsData'
import AWS from 'aws-sdk'
import {IWorkflowFormInputs} from '../../../../types/types'
import useChatGPTRequest from '../../../hooks/useChatGPTRequest'
import useRelevanceRequest from '../../../hooks/useRelevanceRequest'
import {showLimitError} from '../../../../utils/showLimitError'
import {CustomTooltip} from '../tooltip/Tooltip'
import useDalleRequest from '../../../hooks/useDalleRequest'
import imageFormData from '../../../data/image-generation/imageFormData'
import {useAuth} from '../../../../app/modules/auth'
import variationsData from '../../../data/universal-data/variationsData'
import socialMediaData from '../../../data/universal-data/socialMediaData'
import {
  useCreateActivityMutation,
  useLazyGetActivityQuery,
} from '../../../../services/activityServiceApi'
import axios from 'axios'
import {toast} from 'react-toastify'

interface CombinedFormProps {
  templateItem: any
  setCurrentAnswer: Function
  setIsLoadingImg?: Function
  setIsLoadingText?: Function
  disabledButtons?: boolean
  setDisabledButtons?: Function
  triggerRegenerateText?: string | number
  triggerRegenerateImg?: string | number
  setPreviewTemplate?: Function
  setImages?: Function
  setMessage?: Function
  handleMessageVisiability?: Function
  setError: Function
}

/* AVALIABLE yet formTypes: hr, regular, blog, social*/

const CombinedForm: FC<CombinedFormProps> = ({
  templateItem,
  setCurrentAnswer,
  setIsLoadingImg = () => {},
  setIsLoadingText = () => {},
  disabledButtons = false,
  setDisabledButtons = () => {},
  triggerRegenerateText = '',
  triggerRegenerateImg = '',
  setPreviewTemplate = () => {},
  setImages = () => {},
  setMessage = () => {},
  handleMessageVisiability = () => {},
  setError = () => {},
}) => {
  const {currentUser} = useAuth()

  const {
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    axiosURL,
    axiosHeader,
    relevanceRequest,
    easyRequest,
    imgGenerate,
    iceBreaker,
    formType,
  } = templateItem

  const {axiosURL: axiosURLDalle, requiredQuestions: requiredQuestionsDalle} = imageFormData

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  })

  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
  const REGION = process.env.REACT_APP_AWS_REGION
  const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION,
  })

  const [inputs, setInputs] = useState<IWorkflowFormInputs>({
    requiredQuestion1: '',
    requiredQuestion2: '',
    requiredQuestion3: '',
    requiredQuestion4: '',
    requiredQuestion5: '',
    additionalQuestion1: '',
    additionalQuestion2: '',
    additionalQuestion3: '',
    optionalSelect1: optionalSelect[0]?.options[0] || '',
    optionalSelect2: optionalSelect[1]?.options[0] || '',
    language: langsData[0],
    variations: variationsData[0],
    media: '',
    imagesInclude: false,
    iceBreakerInclude: false,
    // file: '',
  })

  const [isVisibleAdditionalQuestion, setIsVisibleAdditionalQuestion] = useState<boolean>(false)
  const myRef = useRef<HTMLFormElement | null>(null)
  const location = window.location
  const uuidFromUrl = new URLSearchParams(location.search).get('uuid')
  const [uuid, setUuid] = useState<string | null>(null)
  const [createActivity] = useCreateActivityMutation()
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const handleSaveActivity = async (currentAnswer: string) => {
    const newActivity = {
      title: templateItem.title,
      url: templateItem.url,
      header: templateItem.header,
      questions: {...inputs},
      currentAnswer,
      user_id: currentUser?.id,
      uuid,
    }
    await createActivity(newActivity)
      .unwrap()
      .then((res) => {
        setUuid(res?.data?.uuid)
      })
  }

  const {handleForm: handleChatGPTForm} = useChatGPTRequest({
    myRef,
    axiosURL,
    axiosHeader,
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    inputs,
    setCurrentAnswer,
    setIsLoading: setIsLoadingText,
    setError,
    handleSaveActivity,
  })

  const {handleForm: handleRelevanceForm} = useRelevanceRequest({
    myRef,
    setCurrentAnswer,
    requiredQuestions,
    additionalQuestions,
    axiosURL,
    easyRequest,
    inputs,
    setError,
    setIsLoading: setIsLoadingText,
    handleSaveActivity,
  })

  const {axiosRequest: dalleRequest} = useDalleRequest({
    axiosURL: axiosURLDalle,
    requiredQuestions: requiredQuestionsDalle,
    inputs,
    setImages,
    setIsLoading: setIsLoadingImg,
    setMessage,
    handleMessageVisiability,
    setError,
  })

  const generateText = (e: React.FormEvent | any) => {
    // setIsLoadingText(true)
    relevanceRequest ? handleRelevanceForm(e) : handleChatGPTForm(e)
  }

  const generateImg = () => {
    // setIsLoadingImg(true)
    dalleRequest()
  }

  const handleForm = async (e: React.FormEvent | any, regenarateImg = false) => {
    const form = myRef.current
    if (form && form.checkValidity()) {
      setDisabledButtons(true)
      inputs?.media && setPreviewTemplate(inputs.media)

      if (e?.type === 'submit') {
        setImages([])
        setCurrentAnswer('')
        generateText(e)
        if (inputs.imagesInclude) {
          generateImg()
        }
      }
      if (e?.type !== 'submit') {
        regenarateImg ? generateImg() : generateText(e)
      }
    }
  }

  const handleInputs = (e: any) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    const name = e.target.name

    setInputs((prev: any) => ({...prev, [name]: value}))
  }

  const handleVisibleAdditQuest = () => {
    setIsVisibleAdditionalQuestion((prev) => !prev)
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (e.target.files !== null && e.target.files.length > 0) {
      // const params = {
      //   ACL: 'public-read',
      //   Body: e.target.files[0],
      //   Bucket: S3_BUCKET ? S3_BUCKET : '',
      //   Key: e.target.files[0].name,
      // }
      // myBucket.putObject(params).send((err: any) => {
      //   if (err) console.log(err)
      // })
      // const fileLink = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${e.target.files[0].name}`

      // setInputs((prev: any) => ({...prev, [name]: fileLink}))
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      setIsUploading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData)
        .then((res: any) => {
          if (res.data.success === true) {
            const fileLink = res.data.location
            setInputs((prev: any) => ({...prev, [name]: fileLink}))
          }
        })
        .catch((e: any) => {
          if (e.response.data.message) {
            toast.error('Please provide a valid file')
          }
        })
        .finally(() => setIsUploading(false))
    }
  }

  const requiredQuestionsBlock = requiredQuestions.map((item: any, index: number) => {
    const {id, title, type, placeholder, tooltipText, tooltipAnchor, formColumn = 12} = item
    const name = `requiredQuestion${index + 1}` as keyof typeof inputs
    return (
      <div className={`col-12 col-md-${formColumn} mb-2 px-1`}>
        <label key={id} className={`${classes.form__label} mb-2`}>
          <h3
            className={classes.form__label__title}
            style={{
              width: formColumn < 12 ? '95%' : '100%',
              marginLeft: formColumn < 12 && index % 2 !== 0 ? '5%' : '0%',
            }}
          >
            {title} <span className={classes.required}>*</span>
            {tooltipAnchor && tooltipText ? (
              <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
            ) : null}
          </h3>
          {type === 'input' ? (
            <textarea
              name={name}
              value={inputs[name]}
              onChange={handleInputs}
              required={true}
              placeholder='Enter'
              style={{
                width: formColumn < 12 ? '95%' : '100%',
                marginLeft: formColumn < 12 && index % 2 !== 0 ? '5%' : '0%',
              }}
            />
          ) : (
            <label className={classes.form__label__fileInput}>
              {inputs[name] ? '+ Change File' : placeholder}
              <input
                type='file'
                name={name}
                required={true}
                onChange={uploadFile}
                style={{
                  width: '95%',
                  marginLeft: formColumn < 12 && index % 2 === 0 ? '0' : '5%',
                }}
              />
            </label>
          )}
        </label>
      </div>
    )
  })

  const additionalQuestionsBlock = additionalQuestions.map((item: any, index: number) => {
    const {id, title, type, placeholder, tooltipText, tooltipAnchor} = item
    const name = `additionalQuestion${index + 1}` as keyof typeof inputs
    return (
      <label key={id} className={classes.form__label}>
        <h3 className={classes.form__label__title}>
          {title}
          {tooltipAnchor && tooltipText ? (
            <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
          ) : null}
        </h3>
        {type === 'input' ? (
          <textarea name={name} value={inputs[name]} onChange={handleInputs} placeholder='Enter' />
        ) : (
          <label className={classes.form__label__fileInput}>
            {inputs[name] ? '+ Change File' : placeholder}
            <input type='file' name={name} required={true} onChange={uploadFile} />
          </label>
        )}
      </label>
    )
  })

  const optionalSelectBlock = optionalSelect.map((item: any, index: number) => {
    const {id, title, options} = item
    const name = `optionalSelect${index + 1}` as keyof typeof inputs
    const optionsBlock = options.map((unit: any, index: any) => (
      <option key={index} value={unit}>
        {unit}
      </option>
    ))

    return (
      <label key={id} className={classes.form__label}>
        <h3 className={classes.form__label__title}>{title}</h3>
        <div className={classes.form__label__selectBlock}>
          <select name={name} value={inputs[name]} onChange={handleInputs}>
            {optionsBlock}
          </select>
          <div className={classes.form__label__selectBlock__imgBlock}>
            <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
          </div>
        </div>
      </label>
    )
  })

  const languageOptionsBlock = langsData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  const variationsOptionsBlock = variationsData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  const mediaOptionsBlock = socialMediaData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  useEffect(() => {
    if (triggerRegenerateText) {
      handleForm(true)
    }
  }, [triggerRegenerateText])

  useEffect(() => {
    if (triggerRegenerateImg) {
      handleForm(true, true)
    }
  }, [triggerRegenerateImg])
  const [fetchActivity, {data: activity}] = useLazyGetActivityQuery()
  useEffect(() => {
    if (uuid) {
      fetchActivity(uuid)
    }
  }, [fetchActivity, uuid])

  useEffect(() => {
    if (activity?.currentAnswer) {
      setCurrentAnswer(activity.currentAnswer)
    }
    if (activity?.questions) {
      setInputs(activity.questions)
    }
  }, [activity, setCurrentAnswer])
  useEffect(() => {
    if (uuidFromUrl) {
      setUuid(uuidFromUrl)
    }
  }, [uuidFromUrl])

  return (
    <form ref={myRef} className={`${classes.form} row`} onSubmit={handleForm}>
      <div className={`row g-0 `}>
        {requiredQuestionsBlock}

        {Object.keys(optionalSelectBlock).length > 0 && (
          <>
            <div className={`col-md-6`}>{optionalSelectBlock}</div>
          </>
        )}

        <div className={`col-md-6`}>
          <label className={classes.form__label}>
            <h3
              className={`${classes.form__label__title} `}
              style={{
                width: Object.keys(optionalSelectBlock).length > 0 ? '95%' : '',
                marginLeft: Object.keys(optionalSelectBlock).length > 0 ? '5%' : '',
              }}
            >
              Output language
            </h3>
            <div className={classes.form__label__selectBlock}>
              <select
                name='language'
                value={inputs.language}
                onChange={handleInputs}
                style={{
                  width: Object.keys(optionalSelectBlock).length > 0 ? '95%' : '',
                  marginLeft: Object.keys(optionalSelectBlock).length > 0 ? '5%' : '',
                }}
              >
                {languageOptionsBlock}
              </select>
              <div className={classes.form__label__selectBlock__imgBlock}>
                <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
              </div>
            </div>
          </label>
        </div>
      </div>

      {formType === 'social' && (
        <div className={classes.form__splitTwo}>
          <label className={classes.form__label}>
            <h3 className={classes.form__label__title}>Number of variations</h3>
            <div className={classes.form__label__selectBlock}>
              <select name='variations' value={inputs.variations} onChange={handleInputs}>
                {variationsOptionsBlock}
              </select>
              <div className={classes.form__label__selectBlock__imgBlock}>
                <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
              </div>
            </div>
          </label>

          <label className={classes.form__label}>
            <h3 className={classes.form__label__title}>
              Media selector <span className={classes.required}>*</span>
            </h3>
            <div className={classes.form__label__selectBlock}>
              <select name='media' value={inputs.media} required={true} onChange={handleInputs}>
                <option value=''>Not selected</option>
                {mediaOptionsBlock}
              </select>
              <div className={classes.form__label__selectBlock__imgBlock}>
                <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
              </div>
            </div>
          </label>
        </div>
      )}
      {imgGenerate && (
        <label className={`${classes.form__label} ${classes.form__labelCheckbox}`}>
          <input type='checkbox' name='imagesInclude' onChange={handleInputs} />
          <h3 className={`${classes.form__label__title} m-0`}>Include images</h3>
        </label>
      )}

      {/* Make sure it is removed */}
      {/* {iceBreaker && (
        <label className={`${classes.form__label} ${classes.form__labelCheckbox}`}>
          <input type='checkbox' name='iceBreakerInclude' onChange={handleInputs} />
          <h3 className={classes.form__label__title}>Include "Ice Breaker" workflow</h3>
        </label>
      )}
      {inputs['iceBreakerInclude'] && (
        <label className={`${classes.form__label}`}>
          <h3 className={classes.form__label__title}>Insert lead's LinkedIn URL</h3>
          <textarea
            name={`linkedin_url`}
            value={inputs['linkedin_url']}
            onChange={handleInputs}
            placeholder='Enter'
          />
        </label>
      )} */}
      {additionalQuestions.length > 0 && (
        <div className={classes.form__additionalQuestions}>
          <div className={classes.form__additionalQuestions__btn} onClick={handleVisibleAdditQuest}>
            <SelectArrowIcon
              className={`${
                isVisibleAdditionalQuestion
                  ? classes.form__additionalQuestions__btn__svgActive
                  : classes.form__additionalQuestions__btn__svg
              }`}
            />
            Additional questions
          </div>
          {isVisibleAdditionalQuestion && additionalQuestionsBlock}
        </div>
      )}
      <button className='universalButton' disabled={disabledButtons || isUploading}>
        Generate
      </button>
    </form>
  )
}

export default CombinedForm
