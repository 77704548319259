import {Modal} from 'react-bootstrap'
import classes from '../profile-page/MembersArea.module.scss'

interface ConfirmationModalProps {
  header: string
  message: string
  handleCancel: () => void
  handleConfirm: () => void
  show: boolean
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  header,
  message,
  handleCancel,
  handleConfirm,
  show,
}) => {
  return (
    //@ts-ignore
    <Modal show={show} centered size='md' onHide={handleCancel}>
      <Modal.Header style={{border: 'none'}}>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <div className={classes.maping__modalSubmitOuter}>
          <button type='button' className={classes.maping__modalCloseButton} onClick={handleCancel}>
            Close
          </button>
        </div>
        <div className={classes.maping__modalSubmitOuter}>
          <button type='button' onClick={handleConfirm} className={`btn btn-primary px-4`}>
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default ConfirmationModal
