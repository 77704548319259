import type {FC} from 'react'
import templatesData from '../../data/sales/salesTemplatesData'
import CombinedWorkflow from '../universal-components/combined-workflow/CombinedWorkflow'

interface SalesPageProps {}

const SalesPage: FC<SalesPageProps> = () => {
  const title = 'Sales'
  const buttonColor = '#8FD7BD'

  return <CombinedWorkflow title={title} buttonColor={buttonColor} itemsData={templatesData} />
}

export default SalesPage
